// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Raleway');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.form-check-label {
    margin-bottom: 0;
    padding-left: 5px;
}
